<template>
    <BlockUI :blocked="loadingCard">
        <ProfileCluster ref="profile-cluster" :item="item" @update-loading-card="updateLoadingCard"/>
    </BlockUI>

	<div class="card ">
		<h5>Filter</h5>
		<hr>
		<div class="p-fluid">
			<div class="formgrid grid">
                <div class="field col-12 md:col-6 lg:col-3">
                    <label>Cluster Beat</label>
                    <MultiSelect dataKey="clusterbeat_id" v-model="filters.clusterbeat_id" :options="dataDropdownClusterBeat" :filter="true" :showToggleAll="false" optionLabel="label" optionValue="clusterbeat_id" placeholder="Select Cluster Beat"/>
                </div>
			</div>
		</div>
        <Button label="Filter" icon="pi pi-search" class="p-button-primary  mr-2 my-1" @click="filter" />
        <Button label="Clear Filter" icon="pi pi-times" class="p-button-warning mr-2 my-1" @click="clearFilter"/>
        <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')"/>
        <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')"/>
	</div>

    <Summary ref="summary" :item="item" :filters="filters" :filterButton="filterButton"/>

    <!-- <Map ref="map" :item="item" :filters="filters" :filterButton="filterButton"/> -->

    <!-- <MapPolygon ref="mappolygon" :item="item" :filters="filters" :filterButton="filterButton" @update-loading-card="updateLoadingCard"/>

    <MapWithOutPolygon ref="mapwithoutpolygon" :item="item" :filters="filters" :filterButton="filterButton" @update-loading-card="updateLoadingCard"/> -->

    <MapNew ref="MapNew" :item="item" :filters="filters" :filterButton="filterButton" @update-loading-card="updateLoadingCard"/>

</template>

<script>
import ProfileCluster from './ProfileCluster.vue';
import Summary from './Summary.vue';
// import Map from './Map.vue';
import MapNew from './MapNew.vue';
// import MapPolygon from './MapPolygon.vue';
// import MapWithOutPolygon from './MapWithOutPolygon.vue';

export default {
    emits: ["submit","update-loading-card"],
    components: {
		'ProfileCluster': ProfileCluster,
		'Summary': Summary,
		// 'Map': Map,
		'MapNew': MapNew,
		// 'MapPolygon': MapPolygon,
		// 'MapWithOutPolygon': MapWithOutPolygon,
	},
    props: ['item'],
    data(){
        return{
            // loading
            loadingCard: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownClusterBeat: null,

            // filter
            filterButton: false,
            filters: {
                cluster_id: null,
                clusterbeat_id: null,
            },
        }
    },
    mounted() {
        this.searchDropdownClusterBeat();
    },
    methods: {
        updateLoadingCard(value) {
            this.$emit('update-loading-card', value);
            this.clearFilter();
            this.dataDropdownClusterBeat= null,
            this.searchDropdownClusterBeat();
        },
        clearFilter(){
            this.filters.clusterbeat_id = null;
            this.filter();
        },
        filter(){
            if(this.filterButton == false){
                this.filterButton = true;
            }else{
                this.filterButton = false;
            }
        },
        // DROPDOWN
        searchDropdownClusterBeat() {
            setTimeout(() => {

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/trans-cluster-beat',
                    params: {
                        "cluster_id" : this.item.trans_cluster.cluster_id
                    }
                })
                .then(res => {
                    this.dataDropdownClusterBeat = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-beat/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
                "project_id": this.item.trans_cluster.project_id,
                "cluster_id": this.item.trans_cluster.cluster_id,
                "clusterbeat_id": this.filters.clusterbeat_id,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Export Cluster.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>